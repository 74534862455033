import $ from 'jquery';

import BpmnModeler from 'bpmn-js/lib/Modeler';
import BpmnColorPickerModule from 'bpmn-js-color-picker';
import diagramXML from '../resources/newDiagram.bpmn';

window.addEventListener('beforeunload', function (e) {
  // Most modern browsers ignore a custom message, so just return true, which
  // will throw up the generic warning about leaving the site.
  e.returnValue = true;
  return true
});

var container = $('#js-drop-zone');

var modeler = new BpmnModeler({
  container: '#js-canvas',
  additionalModules: [
    BpmnColorPickerModule
  ],
  keyboard: {
    bindTo: window
  }
});

function createNewDiagram() {
  openDiagram(diagramXML);
}

async function openDiagram(xml) {
  try {
    await modeler.importXML(xml);
    container
      .removeClass('with-error')
      .addClass('with-diagram');
  } catch (err) {
    container
      .removeClass('with-diagram')
      .addClass('with-error');
    container.find('.error pre').text(err.message);
    console.error(err);
  }
}

function registerFileDrop(container, callback) {

  function handleFileSelect(e) {
    e.stopPropagation();
    e.preventDefault();

    var files = e.dataTransfer.files;
    var file = files[0];
    var reader = new FileReader();
    reader.onload = function(e) {
      var xml = e.target.result;
      callback(xml);
    };

    reader.readAsText(file);
  }

  function handleDragOver(e) {
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy.
  }

  container.get(0).addEventListener('dragover', handleDragOver, false);
  container.get(0).addEventListener('drop', handleFileSelect, false);
}


// file drag / drop ///////////////////////

// check file api availability
if (!window.FileList || !window.FileReader) {
  window.alert(
    'Looks like you use an older browser that does not support drag and drop. ' +
    'Try using Chrome, Firefox or the Internet Explorer > 10.');
} else {
  registerFileDrop(container, openDiagram);
}

// bootstrap diagram functions

$(function() {

  $('#js-zoom-in').click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    modeler.get('zoomScroll').stepZoom(1);
  });

  $('#js-zoom-out').click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    modeler.get('zoomScroll').stepZoom(-1);
  });

  $('#js-zoom-reset').click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    modeler.get('zoomScroll').zoom(0);
  });

  $('#js-create-diagram').click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    createNewDiagram();
  });

  var downloadLink = $('#js-download-diagram');
  var downloadSvgLink = $('#js-download-svg');

  $('#file-prefix').keyup(function(e) {
    document.title = $(this).val() + ' BPMN';
  });

  $('.buttons a').click(function(e) {
    if (!$(this).is('.enabled')) {
      e.preventDefault();
      e.stopPropagation();
    }
    else {
      let filePrefix = $('#file-prefix').val();
      if (filePrefix) {
        filePrefix = filePrefix.replace(/[^a-zA-Z0-9-_]+/ig,'-') + '--';
      }
      else {
        window.alert('Missing filename prefix (e.g., "my-project")');
        e.preventDefault();
        e.stopPropagation();
      }
      let fileName = $(this).attr('download');
      $(this).attr('download', filePrefix + fileName);
    }
  });

  function setEncoded(link, fileExt, data) {
    const encodedData = encodeURIComponent(data);

    if (data) {
      let fileName = `${(new Date().toJSON().slice(0,19)).replace('T', '_').replaceAll(':', '-')}`;
      link.addClass('enabled').attr({
        'href': 'data:application/bpmn20-xml;charset=UTF-8,' + encodedData,
        'download': `${fileName}.${fileExt}`
      });
    } else {
      link.removeClass('enabled');
    }
  }

  var exportArtifacts = debounce(async function() {
    try {
      const { svg } = await modeler.saveSVG();
      setEncoded(downloadSvgLink, 'svg', svg);
    } catch (err) {
      console.error('Error happened saving svg: ', err);
      setEncoded(downloadSvgLink, 'svg', null);
    }

    try {
      const { xml } = await modeler.saveXML({ format: true });
      setEncoded(downloadLink, 'bpmn', xml);
    } catch (err) {
      console.error('Error happened saving XML: ', err);
      setEncoded(downloadLink, 'bpmn', null);
    }
  }, 500);

  modeler.on('commandStack.changed', exportArtifacts);
});



// helpers //////////////////////

function debounce(fn, timeout) {
  var timer;

  return function() {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(fn, timeout);
  };
}
